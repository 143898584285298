import React, { useContext } from "react"
import styled from "styled-components"
import { AppContext } from "../../contexts/AppContext"
import { Link } from "gatsby"
import UserIcon from "../../images/user_icon_big.png"
import {
  COLOR_DEFAULT_TEXT_DARK,
  COLOR_DEFAULT_TEXT_DISABLED,
} from "../../constants/colors"

import { MOBILE, TAB } from "../../constants/breakpoints"

const MainContent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 25vw;
  margin: 10px auto;
`
const MainHeading = styled.h3`
  padding-top: 1.42vh;
  display: flex;
  justify-content: center;
  color: #eeeeee;
  font-weight: normal;
  margin: 0;
  ::selection {
    color: none;
    background: none;
  }
  font-size: ${MOBILE.publicPages.welcome.font.heading}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.publicPages.welcome.font.heading}px;
  }

  margin-bottom: 25px;
`
const UserImage = styled.img`
  width: 100px;
  margin: 0 auto;
`
const UserName = styled.p`
  color: #fff;
  margin: 5px auto 0px;
  font-size: ${MOBILE.publicPages.welcome.font.username}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.publicPages.welcome.font.username}px;
  }
`
const UserEmail = styled.p`
  color: #fff;
  margin: 5px auto 0px;
  font-size: ${MOBILE.publicPages.welcome.font.email}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.publicPages.welcome.font.email}px;
  }
`
const WelcomeMessage = styled.p`
  color: #fff;
  margin: 5px auto 0px;
  margin-top: 15px;
  font-size: ${MOBILE.publicPages.welcome.font.message}px;
  @media (min-width: ${TAB.minWidth}px) {
    font-size: ${TAB.publicPages.welcome.font.message}px;
  }
`
const FormSubmitButton = styled.button`
  width: 50%;
  background-color: #ffffff;
  height: auto;
  font-size: 18px;
  border-radius: 5px;
  padding: 7px;
  align-self: center;
  margin: 20px 0px;
  & a {
    color: ${COLOR_DEFAULT_TEXT_DARK};
    &:hover {
      color: ${COLOR_DEFAULT_TEXT_DARK};
      text-decoration: none;
    }
  }
  ::selection {
    color: none;
    background: none;
    border: none;
  }
  :disabled {
    color: ${COLOR_DEFAULT_TEXT_DISABLED};
  }
`
const ContentWelcome = props => {
  let { userState } = useContext(AppContext)

  return (
    <MainContent>
      <MainHeading>Welcome</MainHeading>
      <UserImage src={UserIcon} />
      <UserName>{userState.firstname}</UserName>
      <UserEmail>{userState.email}</UserEmail>
      <WelcomeMessage>
        <span>
          Please activate your profile by clicking on the activation link sent
          to your email address. Then
        </span>
        <Link to="/login"> Sign in </Link>
        <span>
          to your account.
        </span>
      </WelcomeMessage>
     </MainContent>
  )
}

export default ContentWelcome
