import React from "react"

import ContentWelcome from "../components/ContentWelcome"

const WelcomeUserPage = () => 
  {
    return (
      <React.Fragment>
        <ContentWelcome />
      </React.Fragment>
    )
  }

export default WelcomeUserPage
